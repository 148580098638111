import React from 'react';
import getReadableTextColor from 'utils/getReadableTextColor';
import styles from 'style-config.scss';
import './ProgressBar.scss';

const ProgressBar = ({
  color,
  displayPercentage = false,
  height = 20,
  percentage,
}: {
  color: string
  displayPercentage?: boolean
  height?: number
  percentage: number
}) => {
  let safePercentage = percentage;
  if (percentage < 0) {
    safePercentage = 0;
  } else if (percentage > 100) {
    safePercentage = 100;
    console.warn('Percentage out of range', percentage);
  }
  const indicatorStyle = {
    width: `${Math.round(safePercentage)}%`,
    backgroundColor: color,
  };
  // Handle when color is dark and handle showing percentage in default $black and offset if indicator is too small to provide contrast
  const smallPercentage = safePercentage < 55;
  const readableColor = smallPercentage ? styles.black : getReadableTextColor(color);
  return (
    <div className="progress-bar" style={{ height: `${height}px` }}>
      <div className="progress-bar__indicator" style={indicatorStyle}>
      </div>
      <div className="progress-bar__percentage" style={{ color: readableColor }}>
        {displayPercentage && `${Math.round(safePercentage)}%`}
      </div>
    </div>
  );
};

export default ProgressBar;

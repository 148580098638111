export enum AdminPathEnum {
  SalesAdmin = 'sales-admin',
  Users = 'users',
  DuplicateCourses = 'duplicate-courses',
  SystemReports = 'system-reports',
  QADashboard = 'qadashboard',
  CourseActivityCheck = 'course-activity-check',
  ContentLibrary = 'library',
  SurveyDistributor = 'survey-distributor',
}

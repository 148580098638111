import React from 'react';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { prettifyAssessType } from 'utils/commonFormattingFunctions';
import './StudyPathTooltipContent.scss';

export const StudyPathAssessmentTooltip = ({
  assessNumber,
  assessType,
  questionNum,
  title,
}: {
  assessNumber: number
  assessType: AssessTypeEnum
  questionNum: number
  title: string
}) => {
  const capitalizedAssessType = prettifyAssessType(assessType);
  return (
    <div className="study-path-assessment-tooltip-content">
      <div>Assessment Name: {title}</div>
      <div>{capitalizedAssessType} #{assessNumber}</div>
      <div>Question #{questionNum}</div>
    </div>
  );
};



import React, { useState } from 'react';

import apiNext from 'api-next';
import { useAppSelector } from 'store';
import { isAdminRole, validEmail, validUuid } from 'utils';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import { UserApi } from 'types/backend/users.types';
import './UsersSearch.scss';


function UsersSearch({
  setUsers,
  setMoreUsersThanLimit,
  setUserNotFound,
  showSearchById = true,
}: {
  setUsers: React.Dispatch<React.SetStateAction<Array<UserApi>>>
  setMoreUsersThanLimit: React.Dispatch<React.SetStateAction<boolean>>
  setUserNotFound: React.Dispatch<React.SetStateAction<boolean>>
  showSearchById?: boolean
}) {
  const user = useAppSelector((store) => store.user);

  const [emailInput, setEmailInput] = useState('');
  const [idInput, setIdInput] = useState('');
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');

  const enableSubmitEmail = validEmail(emailInput);
  const enableSubmitId = validUuid(idInput);
  const enableSubmitName = !!(firstNameInput || lastNameInput);

  const getUserById = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && enableSubmitId) {
      const { data, total, limit } = await apiNext.getNonAdminUserById(idInput);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    if (!newUsers.length) {
      setUserNotFound(true);
      setMoreUsersThanLimit(false);
    } else {
      setUserNotFound(false);
      setMoreUsersThanLimit(moreUsers);
    }
    setUsers(newUsers);
  };

  const getUsersByEmail = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && enableSubmitEmail) {
      const { data, total, limit } = await apiNext.getNonAdminUsersByEmail(emailInput);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    if (!newUsers.length) {
      setUserNotFound(true);
      setMoreUsersThanLimit(false);
    } else {
      setUserNotFound(false);
      setMoreUsersThanLimit(moreUsers);
    }
    setUsers(newUsers);
  };

  const getUsersByName = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && enableSubmitName) {
      let searchString = '';
      if (!!firstNameInput) {
        searchString = searchString.concat(`&firstName[$ilike]=${encodeURIComponent(firstNameInput)}`);
      }
      if (!!lastNameInput) {
        searchString = searchString.concat(`&lastName[$ilike]=${encodeURIComponent(lastNameInput)}`);
      }
      const { data, total, limit } = await apiNext.getNonAdminUsersByName(searchString);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    if (!newUsers.length) {
      setUserNotFound(true);
      setMoreUsersThanLimit(false);
    } else {
      setUserNotFound(false);
      setMoreUsersThanLimit(moreUsers);
    }
    setUsers(newUsers);
  };

  return (
    <div className="users-search">
      <div className="users-search__row">
        <form className="users-search__form" onSubmit={getUsersByEmail}>
          <label htmlFor="user-email">Email</label>
          <input
            id="user-email"
            type="text"
            value={emailInput}
            placeholder="Email"
            onChange={e => setEmailInput(e.target.value)}
          />
          <BetterButton
            className="users-search__search-button"
            disabled={!enableSubmitEmail}
            primary
            text="Search by Email"
            onClick={getUsersByEmail}
          />
        </form>
        {showSearchById && (
          <form className="users-search__form" onSubmit={getUserById}>
            <label htmlFor="user-id">User Id</label>
            <input
              id="user-id"
              type="text"
              value={idInput}
              placeholder="User Id"
              onChange={e => setIdInput(e.target.value)}
            />
            <BetterButton
              className="users-search__search-button"
              disabled={!enableSubmitId}
              primary
              text="Search by User Id"
              onClick={getUserById}
            />
          </form>
        )}
      </div>
      <div className="users-search__row">
        <form className="users-search__form" onSubmit={getUsersByName}>
          <label htmlFor="user-first-name">First name</label>
          <input
            id="user-first-name"
            type="text"
            value={firstNameInput}
            placeholder="First Name"
            onChange={e => setFirstNameInput(e.target.value)}
          />
          <label htmlFor="user-last-name">Last name</label>
          <input
            id="user-last-name"
            type="text"
            value={lastNameInput}
            placeholder="Last Name"
            onChange={e => setLastNameInput(e.target.value)}
          />
          <BetterButton
            className="users-search__search-button"
            disabled={!enableSubmitName}
            primary
            text="Search by Name"
            onClick={getUsersByName}
          />
        </form>
      </div>
    </div>
  );
}

export default UsersSearch;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'store';
import setMasqueradeUser from 'store/actions/setMasqueradeUser';
import { formatName, mapSystemRole } from 'utils/commonFormattingFunctions';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import UsersSearch from 'shared-components/UsersSearch/UsersSearch';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApi } from 'types/backend/users.types';
import './UsersContainer.scss';

export default function UsersContainer() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [users, setUsers] = useState<Array<UserApi>>([]);
  const [moreUsersThanLimit, setMoreUsersThanLimit] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);

  const launchUserDashboard = (masqueradeUser: UserApi) => {
    dispatch(setMasqueradeUser(masqueradeUser));
    const startRoute = masqueradeUser.roleId === RoleEnum.Student
      ? '/student'
      : '/instructor';
    history.push(startRoute);
  };

  return (
    <div className="users-container">
      <p>Search for non-admin user by email, id, or name</p>
      <UsersSearch
        setUsers={setUsers}
        setMoreUsersThanLimit={setMoreUsersThanLimit}
        setUserNotFound={setUserNotFound}
      />
      <div className="users-container-table">
        {moreUsersThanLimit && (
          <p>There are more users than can be shown on this page. You may need to narrow your search results.</p>
        )}
        {userNotFound && (
          <p>User not found.</p>
        )}
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>name</th>
              <th>email</th>
              <th>system role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!!users && users.map(user => {
              const { id, lastName, firstName, email, roleId } = user;
              const name = formatName(lastName, firstName);
              const role = mapSystemRole(roleId);
              return (
                <tr className="users-container-table__item-row" key={`users-row_${id}`}>
                  <td>
                    {id}
                  </td>
                  <td>
                    {name}
                  </td>
                  <td>
                    {email}
                  </td>
                  <td>
                    {role}
                  </td>
                  <td>
                    <BetterButton
                      primary
                      text="Masquerade"
                      className="users-container-table__launch-masquerade-button"
                      onClick={() => launchUserDashboard(user)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

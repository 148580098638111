import React, { useState } from 'react';
import CreateNewUsers from './CreateNewUsers';
import EnrollUsersInCourses from './EnrollUsersInCourses';
import './SalesAdmin.scss';


enum SalesAdminTab {
  CreateUsers = 'Create new users',
  EnrollUsers = 'Enroll users in courses'
}

function SalesAdmin() {
  const [selectedTab, setSelectedTab] = useState<SalesAdminTab>(SalesAdminTab.CreateUsers);
  const renderTab = () => {
    switch (selectedTab) {
      case SalesAdminTab.CreateUsers:
        return (
          <CreateNewUsers />
        );
      case SalesAdminTab.EnrollUsers:
        return <EnrollUsersInCourses />;
    }
  };

  return (
    <div className="sales-admin">
      <div className="sales-admin__nav">
        {Object.values(SalesAdminTab).map(tab => (
          <button
            className={`sales-admin__nav ${tab === selectedTab ? 'selected' : ''}`}
            onClick={() => setSelectedTab(tab)}
            key={tab}
            id={tab}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="sales-admin__content">
        {renderTab()}
      </div>
    </div>
  );
}

export default SalesAdmin;

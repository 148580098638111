import React, { useState } from 'react';
import { DateTime } from 'luxon';
import apiNext, { SimplifiedErrorResponse } from 'api-next';
import { useAppSelector } from 'store';
import { formatName, mapSystemRole } from 'utils/commonFormattingFunctions';
import { isAdminRole, validEmail, validUuid } from 'utils';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import ApiErrorDisplay, { ApiError } from 'shared-components/ApiErrorDisplay/ApiErrorDisplay';
import { CourseApi } from 'types/backend/courses.types';
import { EnrollmentApi, MethodEnum } from 'types/backend/enrollments.types';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApi } from 'types/backend/users.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import './SalesAdmin.scss';

type CourseAndInstructorName = CourseApi & { instructorName: string }

function EnrollUsersInCourses() {
  const user = useAppSelector((store) => store.user);

  const [emailInput, setEmailInput] = useState('');
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const enableSubmitEmail = validEmail(emailInput);
  const enableSubmitName = !!(firstNameInput || lastNameInput);

  const [users, setUsers] = useState<Array<UserApi>>([]);
  const [moreUsersThanLimit, setMoreUsersThanLimit] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserApi>();

  const [courseIdInput, setCourseIdInput] = useState('');
  const [course, setCourse] = useState<CourseAndInstructorName>({} as CourseAndInstructorName);
  const [courseNotFound, setCourseNotFound] = useState(false);
  const enableSubmitCourseId = validUuid(courseIdInput);

  const [createEnrollmentError, setCreateEnrollmentError] = useState<ApiError>();
  const [createEnrollmentErrorDupe, setCreateEnrollmentErrorDupe] = useState(false);
  const [createdEnrollment, setCreatedEnrollment] = useState<EnrollmentApi>();

  const getUsersByEmail = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && enableSubmitEmail) {
      const { data, total, limit } = await apiNext.getNonAdminUsersByEmail(emailInput);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    if (!newUsers.length) {
      setUserNotFound(true);
      setMoreUsersThanLimit(false);
    } else {
      setUserNotFound(false);
      setMoreUsersThanLimit(moreUsers);
    }
    setUsers(newUsers);
  };

  const getUsersByName = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && enableSubmitName) {
      let searchString = '';
      if (!!firstNameInput) {
        searchString = searchString.concat(`&firstName[$ilike]=${encodeURIComponent(firstNameInput)}`);
      }
      if (!!lastNameInput) {
        searchString = searchString.concat(`&lastName[$ilike]=${encodeURIComponent(lastNameInput)}`);
      }
      const { data, total, limit } = await apiNext.getNonAdminUsersByName(searchString);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    if (!newUsers.length) {
      setUserNotFound(true);
      setMoreUsersThanLimit(false);
    } else {
      setUserNotFound(false);
      setMoreUsersThanLimit(moreUsers);
    }
    setUsers(newUsers);
  };

  const getCourseById = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isAdminRole(user) && enableSubmitCourseId) {
      const foundCourse = await apiNext.getCourse(courseIdInput.toLowerCase().trim());
      const { error } = foundCourse as SimplifiedErrorResponse;
      if (error) {
        const { name } = error;
        setCourse({} as CourseAndInstructorName);
        if (name === 'NotFound') {
          setCourseNotFound(true);
        }
      } else {
        const instructorUserRes = await apiNext.getNonAdminUserById((foundCourse as CourseApi).creatorUserId);
        const { data: [instructorUser] } = instructorUserRes;
        const instructorName = formatName(instructorUser.firstName, instructorUser.lastName);
        setCourse({
          ...foundCourse as CourseApi,
          instructorName,
        });
      }
    }
  };

  const createEnrollment = async () => {
    if (selectedUser) {
      const newEnrollment = await apiNext.createEnrollment({
        userId: selectedUser.id,
        courseId: course.id,
        roleId: RoleEnum.Student,
        method: MethodEnum.Free,
      });
      const { error } = newEnrollment as SimplifiedErrorResponse;
      if (error) {
        const { name, code, message, errorCode } = error;
        if (errorCode === CodonErrorCode.CreateEnrollmentAlreadyExists) {
          setCreateEnrollmentErrorDupe(true);
        } else {
          setCreateEnrollmentError({ name, code, message });
        }
      } else {
        setCreatedEnrollment(newEnrollment as EnrollmentApi);
      }
    }
  };

  const resetUsersForm = () => {
    setEmailInput('');
    setFirstNameInput('');
    setLastNameInput('');
  };

  const resetUsersResult = () => {
    setUserNotFound(false);
    setMoreUsersThanLimit(false);
    setUsers([]);
    setSelectedUser(undefined);
  };

  const resetCourseForm = () => {
    setCourseIdInput('');
  };

  const resetCourseResult = () => {
    setCourseNotFound(false);
    setCourse({} as CourseAndInstructorName);
  };

  const resetEnrollmentResult = () => {
    setCreateEnrollmentError(undefined);
    setCreateEnrollmentErrorDupe(false);
    setCreatedEnrollment(undefined);
  };

  return (
    <>
      <div className="enroll-user">
        <div className="enroll-user__user">
          <>
            <h2>1. Search for user by email or name</h2>
            <div className="users-search">
              <div className="users-search__row">
                <form className="users-search__form" onSubmit={getUsersByEmail}>
                  <label htmlFor="user-email">Email</label>
                  <input
                    id="user-email"
                    type="text"
                    value={emailInput}
                    placeholder="Email"
                    onChange={e => {
                      setEmailInput(e.target.value);
                      resetUsersResult();
                      resetEnrollmentResult();
                    }}
                  />
                  <BetterButton
                    className="users-search__search-button"
                    disabled={!enableSubmitEmail}
                    primary
                    text="Search by Email"
                    onClick={getUsersByEmail}
                  />
                </form>
              </div>
              <div className="users-search__row">
                <form className="users-search__form" onSubmit={getUsersByName}>
                  <label htmlFor="user-first-name">First name</label>
                  <input
                    id="user-first-name"
                    type="text"
                    value={firstNameInput}
                    placeholder="First Name"
                    onChange={e => {
                      setFirstNameInput(e.target.value);
                      resetUsersResult();
                      resetEnrollmentResult();
                    }}
                  />
                  <label htmlFor="user-last-name">Last name</label>
                  <input
                    id="user-last-name"
                    type="text"
                    value={lastNameInput}
                    placeholder="Last Name"
                    onChange={e => {
                      setLastNameInput(e.target.value);
                      resetUsersResult();
                      resetEnrollmentResult();
                    }}
                  />
                  <BetterButton
                    className="users-search__search-button"
                    disabled={!enableSubmitName}
                    primary
                    text="Search by Name"
                    onClick={getUsersByName}
                  />
                </form>
              </div>
            </div>
          </>
          <div className="enroll-user__user-search-result">
            <div className="users-container-table">
              {moreUsersThanLimit && (
                <p>There are more users than can be shown on this page. You may need to narrow your search results.</p>
              )}
              {userNotFound && (
                <p>User not found.</p>
              )}
              <table>
                <thead>
                  <tr>
                    <th className="users-table__name">name</th>
                    <th className="users-table__email">email</th>
                    <th>system role</th>
                    <th>agreed to terms of service on</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(u => {
                    const { id, lastName, firstName, email, roleId, agreementDate } = u;
                    const name = formatName(lastName, firstName);
                    const role = mapSystemRole(roleId);
                    const agreementDateOnly = agreementDate ? DateTime.fromISO(agreementDate).toFormat(DateFormatEnum.ShortMonthDate) : 'never';
                    return (
                      <tr className={`users-container-table__item-row ${id === selectedUser?.id ? 'is-selected' : ''}`} key={`users-row_${id}`}>
                        <td>
                          {name}
                        </td>
                        <td>
                          {email}
                        </td>
                        <td>
                          {role}
                        </td>
                        <td>
                          {agreementDateOnly}
                        </td>
                        <td>
                          <BetterButton
                            primary
                            text="Select"
                            className="users-container-table__select-user"
                            onClick={() => {
                              setSelectedUser(u);
                              resetEnrollmentResult();
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="enroll-user__course">
          <h2>2. Search for course by id</h2>
          <form className="enroll-user__course-search-form users-search__form" onSubmit={getCourseById}>
            <label htmlFor="course-id-search">Course id</label>
            <input
              id="course-id-search"
              type="text"
              value={courseIdInput}
              placeholder="course id"
              onChange={e => {
                setCourseIdInput(e.target.value);
                resetCourseResult();
                resetEnrollmentResult();
              }}
            />
            <BetterButton
              disabled={!enableSubmitCourseId}
              primary
              text="Search for Course"
              onClick={getCourseById}
            />
          </form>
          <div className="enroll-user__course-search-result">
            <div className="users-container-table">
              {courseNotFound && (
                <p>Course with id {courseIdInput} not found.</p>
              )}
              <table>
                <thead>
                  <tr>
                    <th className="courses-table__id">course id</th>
                    <th className="courses-table__name">course name</th>
                    <th className="courses-table__number">course number</th>
                    <th className="courses-table__date">start date</th>
                    <th className="courses-table__user">instructor name</th>
                  </tr>
                </thead>
                <tbody>
                  {!!Object.keys(course).length && (
                    <tr className='users-container-table__item-row' key={`courses-row_${course.id}`}>
                      <td>
                        {course.id}
                      </td>
                      <td>
                        {course.name}
                      </td>
                      <td>
                        {course.courseNumber}
                      </td>
                      <td>
                        {DateTime.fromISO(course.startDate).toFormat(DateFormatEnum.ShortMonthDate)}
                      </td>
                      <td>
                        {course.instructorName}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="enroll-user__enroll">
          {!!users.length && !selectedUser && (
            <p className="enroll-user__enroll-select-user">Select a user to enroll</p>
          )}
          {selectedUser && !!Object.keys(course).length && (
            <>
              <div className="enroll-user__enroll-prompt">
                <p>3. Enroll {selectedUser.email} as a student in {course.name}?</p>
                <BetterButton
                  className="submit-button"
                  primary
                  text="Submit"
                  onClick={createEnrollment}
                  disabled={!!(createdEnrollment || createEnrollmentErrorDupe || createEnrollmentError)}
                />
              </div>
              <div className="result">
                {createdEnrollment && (
                  <div className="success">User {selectedUser.email} successfully enrolled in {course.name}</div>
                )}
                {createEnrollmentErrorDupe && (
                  <div className="warn">User {selectedUser.email} is already enrolled in {course.name}</div>
                )}
                {createEnrollmentError && (
                  <>
                    <span className="error">Error creating enrollment</span>
                    <ApiErrorDisplay
                      error={createEnrollmentError}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <BetterButton
          className="reset-button"
          text="Reset"
          primary
          onClick={() => {
            resetEnrollmentResult();
            resetCourseResult();
            resetUsersResult();
            resetCourseForm();
            resetUsersForm();
          }}
        />
      </div>
    </>
  );
}

export default EnrollUsersInCourses;

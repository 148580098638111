import React from 'react';
import styles from 'style-config.scss';
import './Charts.scss';

interface BarDataObject {
  name: string
  value: number
}

function BarGroup({ color, barData, barHeight }: {
  barData: BarDataObject
  barHeight: number
  color: string
}) {
  const barPadding = 2;
  const widthScale = (dd: number) => dd * 30;

  const width = widthScale(barData.value);
  const yMid = barHeight * 0.5;
  const isZero = barData.value === 0;
  return (
    <g className="bar-group">
      <text
        className="name-label"
        x="-6"
        y={yMid}
        alignmentBaseline="middle"
      >
        {barData.name}
      </text>
      <rect y={barPadding * 0.5} width={width} height={barHeight - barPadding} fill={color} />
      <text
        className={`value-label ${isZero ? 'is-zero' : ''}`}
        x={isZero ? width + 8 : width - 8}
        y={yMid}
        alignmentBaseline="middle"
      >
        {barData.value}
      </text>
    </g>
  );
}

function BarChart({ data, handleHover }: {
  data: Array<BarDataObject>
  handleHover: (name: string) => void
}) {
  const barHeight = 40;
  const totalHeight = (barHeight + 5) * data.length;
  return (
    <svg width="100%" height={totalHeight}>
      <g className="container">
        <g className="chart" transform="translate(60, 0)">
          {
            data.map((barData, i) => (
              <g
                transform={`translate(0, ${i * barHeight})`}
                key={`bargroup-${barData.name}`}
                onMouseEnter={() => handleHover(barData.name)}
                onMouseLeave={() => handleHover('')}
              >
                <BarGroup
                  barData={barData}
                  barHeight={barHeight}
                  color={i % 2 ? styles.purple : styles.blue }
                />
              </g>
            ))
          }
        </g>
      </g>
    </svg>
  );
}

export default BarChart;

import React, { useEffect, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ChildrenProp, PortalEnum } from 'types/common.types';
import './BetterModal.scss';

const BetterModal = ({
  children,
  className = '',
  disableClose = false,
  hide,
  isShowing,
  small,
  trapFocus = false,
}: {
  children: ChildrenProp
  className?: string
  disableClose?: boolean
  hide: () => void
  isShowing: boolean
  small?: boolean
  trapFocus?: boolean
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (!disableClose) {
      hide();
    }
  }, true);
  // prevent background from responding to scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = isShowing ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isShowing]);
  const portalElement = document.getElementById(PortalEnum.ModalPortal);
  if (!isShowing || !portalElement) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <FocusLock
        as="section"
        disabled={!trapFocus}
        returnFocus
      >
        <div className="modal-wrapper" ref={ref} aria-modal aria-hidden tabIndex={-1} role="dialog">
          <div className={`modal ${className} ${small && 'modal-small'}`}>
            {!disableClose && (
              <div className="modal-header">
                <BetterButton
                  className="modal-close-button"
                  data-dismiss="modal"
                  onClick={hide}
                  showText={false}
                  text="Close"
                  icon={() => <FaTimes />}
                />
              </div>
            )}
            <div className="modal-content">
              {children}
              <div className="app__modal" id={PortalEnum.ModalTooltipPortal}></div>
            </div>
          </div>
        </div>
      </FocusLock>
    </div>, portalElement
  );
};

BetterModal.propTypes = {
  className: PropTypes.string,
  disableClose: PropTypes.bool,
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
};

export default BetterModal;

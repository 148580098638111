import React from 'react';
import Icon, { IconEnum } from 'shared-components/Icon';

export enum StudyPathTooltipContentEnum {
  WhatIsStudyPath = 'what-is-study-path',
  IShouldReview = 'i-should-review',
  PrepMe = 'prep-me',
  TestMe = 'test-me',
}

const StudyPathTooltipContent = ({ className, which }: {
  which: StudyPathTooltipContentEnum
  className?: string
}) => {
  const tooltipContentClassName = `study-path-tooltip-content ${className}`;
  switch (which) {
    case StudyPathTooltipContentEnum.WhatIsStudyPath: {
      return (
        <div className={tooltipContentClassName}>
          <h3>Study Path</h3>
          <p>
            <b>OBJECTIVE</b> - Expand the Topic Cards and answer questions related to the Learning Objectives in order to move Topic Cards from Checkpoint 1 to 3 where you can unlock the Practice Test. Topic cards first appear in the Study Path after you complete a homework assignment.
          </p>
          <p>
            <b>CHECKPOINT 1</b> - Answer questions incorrectly on assignments and they end up in Checkpoint 1 for you to review. Retry those questions to move each Topic Card to Checkpoint 2 - Prep Me. You might even be able to recapture some points you missed!
          </p>
          <p>
            <b>CHECKPOINT 2</b> - Answer Prep question to strengthen your understanding of each topic. Correctly answer these prep questions to move each Topic Card to Checkpoint 3 - Test Me!
          </p>
          <p>
            <b>CHECKPOINT 3</b> - Move all of the Topic Cards to Checkpoint 3 to unlock the Practice Test. Take the Practice Test to see how prepared you are for the real thing. After taking the Practice Test, prioritize your study time by retrying the questions that you answered incorrectly in each Topic Card.
          </p>
        </div>
      );
    }
    case StudyPathTooltipContentEnum.IShouldReview: {
      return (
        <div className={tooltipContentClassName}>
          <Icon which={IconEnum.Redo} size={56} />
          <div className="study-path-tooltip__checkpoint-title">CHECKPOINT 1</div>
          <h3>I Should Review</h3>
          <p>
            Topic cards appear in Checkpoint 1 on your Study Path when you answer questions incorrectly in your homework assignment or indicate that they were confusing, or “muddy.”
          </p>
          <p>
            Reflecting on confusing questions and quizzing yourself is a highly effective study strategy. Retry questions here to move each topic card to the Prep Me checkpoint. You might even be able to recapture some points you missed!
          </p>
          <div className="study-path-tooltip__checkpoint-footer">
            <small>
              (If you’d rather not review right now, click Skip for now at the bottom of the topic card to move it to Checkpoint 2.)
            </small>
          </div>
        </div>
      );
    }
    case StudyPathTooltipContentEnum.PrepMe: {
      return (
        <div className={tooltipContentClassName}>
          <Icon which={IconEnum.Barbell} size={56} />
          <div className="study-path-tooltip__checkpoint-title">CHECKPOINT 2</div>
          <h3>Prep Me</h3>
          <p>
            Topic cards move to Checkpoint 2 when you correctly answer questions in your homework assignment or in Checkpoint 1.
          </p>
          <p>
            Get extra practice by completing new questions that are designed to strengthen your understanding of each topic. Correctly answer these prep questions to move each topic card to the Test Me! checkpoint.
          </p>
          <div className="study-path-tooltip__checkpoint-footer">
            <small>
              (Answering questions is a good study strategy, but if you’d rather not prep right now, click Skip for now at the bottom of the topic card to move it to Checkpoint 3.)
            </small>
          </div>
        </div>
      );
    }
    case StudyPathTooltipContentEnum.TestMe: {
      return (
        <div className={tooltipContentClassName}>
          <Icon which={IconEnum.Pencil} size={56} />
          <div className="study-path-tooltip__checkpoint-title">CHECKPOINT 3</div>
          <h3>Test Me!</h3>
          <p>
            Topic cards move to Checkpoint 3 when you correctly answer prep questions in Checkpoint 2. The Practice Test Meter indicates your progress. Once the meter reaches 100%, take the Practice Test to see how prepared you are for the real thing.
          </p>
          <p>
            Testing yourself makes clear what you know and what you don’t know so that you can prioritize your study time.
          </p>
          <div className="study-path-tooltip__checkpoint-footer">
            <small>
              (Answering questions is a good study strategy, but if you’d rather not prep right now, click Skip for now at the bottom of the topic card to move it to Checkpoint 3.)
            </small>
          </div>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default StudyPathTooltipContent;

import React, { useState } from 'react';
import Select from 'react-select';
import apiNext, { SimplifiedErrorResponse } from 'api-next';
import { validEmail } from 'utils';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import ApiErrorDisplay, { ApiError } from 'shared-components/ApiErrorDisplay/ApiErrorDisplay';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApi } from 'types/backend/users.types';
import { CodonErrorCode } from 'types/backend/error.types';
import './SalesAdmin.scss';

function CreateNewUsers() {
  const [emailInput, setEmailInput] = useState('');
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [roleId, setRoleId] = useState(RoleEnum.Instructor);
  const [createUserError, setCreateUserError] = useState<ApiError>();
  const [createUserErrorDupe, setCreateUserErrorDupe] = useState(false);
  const [createdUser, setCreatedUser] = useState<UserApi>();
  const rolesForDropdown = [
    {
      value: RoleEnum.Instructor,
      label: 'Instructor',
    },
    {
      value: RoleEnum.Student,
      label: 'Student',
    },
  ];

  const enableSubmit = validEmail(emailInput) && !(createdUser || createUserErrorDupe || createUserError);

  const createUser = async () => {
    const newUser = await apiNext.createUser({
      email: emailInput.toLowerCase().trim(),
      firstName: !!firstNameInput.trim() ? firstNameInput.trim() : null,
      lastName: !!lastNameInput.trim() ? lastNameInput.trim() : null,
      roleId,
    });
    const { error } = newUser as SimplifiedErrorResponse;
    if (error) {
      const { name, code, message, errorCode } = error;
      if (errorCode === CodonErrorCode.CreateUserAlreadyExists) {
        setCreateUserErrorDupe(true);
      } else {
        setCreateUserError({ name, code, message });
      }
    } else {
      setCreatedUser(newUser as UserApi);
    }
  };

  const resetForm = () => {
    setEmailInput('');
    setFirstNameInput('');
    setLastNameInput('');
    setRoleId(RoleEnum.Instructor);
  };

  const resetResult = () => {
    setCreateUserError(undefined);
    setCreateUserErrorDupe(false);
    setCreatedUser(undefined);
  };

  return (
    <>
      <h2>Create User</h2>
      <div className="new-user">
        <form className="new-user__form">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="text"
            value={emailInput}
            placeholder="new user email"
            onChange={e => {
              setEmailInput(e.target.value);
              resetResult();
            }}
          />
          <label htmlFor="first-name">First Name</label>
          <input
            id="first-name"
            type="text"
            value={firstNameInput}
            placeholder="new user first name"
            onChange={e => {
              setFirstNameInput(e.target.value);
              resetResult();
            }}
          />
          <label htmlFor="last-name">Last Name</label>
          <input
            id="last-name"
            type="text"
            value={lastNameInput}
            placeholder="new user last name"
            onChange={e => {
              setLastNameInput(e.target.value);
              resetResult();
            }}
          />
          <label htmlFor="system-role">Role</label>
          <Select
            className="form-select"
            id="system-role"
            options={rolesForDropdown}
            value={rolesForDropdown.find(v => v.value === roleId)}
            name="systemRoleId"
            onChange={(option) => {
              !!option && setRoleId(option.value);
              resetResult();
            }}
          />
          <BetterButton
            className="submit-button"
            disabled={!enableSubmit}
            primary
            text="Create new user"
            onClick={createUser}
          />
          <BetterButton
            className="reset-button"
            text="Reset"
            primary
            onClick={() => {
              resetForm();
              resetResult();
            }}
          />
        </form>
        <div className="result">
          {createdUser && (
            <div className="success">New user "{createdUser.email}" created successfully</div>
          )}
          {createUserErrorDupe && (
            <div className="warn">User with email "{emailInput}" already exists</div>
          )}
          {createUserError && (
            <>
              <span className="error">Error creating new user</span>
              <ApiErrorDisplay
                error={createUserError}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateNewUsers;

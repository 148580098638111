import React from 'react';
import sharedStrings from 'sharedStrings';
import { AssessTypeEnum } from 'types/backend/assessments.types';

const confirmationMsgs = {
  prepConfMessage: (
    <div>
      Students have started working in this Study Path.
      <br/><br/>
      Entering Edit Mode will enable a limited number of changes.
      Changing points assigned, adding, or removing Prep Questions will change the total points available (if any) and may cause Topic Cards to rearrange in student Study Paths.
      <br/><br/>
      You may want to notify students of changes.
    </div>
  ),
  nonPrepConfMessage: (
    <div>
      Students have started working on this assessment.
      <br/><br/>
      Entering Edit Mode will enable a limited number of changes.
      Changing points assigned or removing questions will change the total points available (if any) and may cause Topic Cards to rearrange in student Study Paths.
      <br/><br/>
      You may want to notify students of changes.
    </div>
  ),
  enableGradeSyncConfMessage: (
    <div>
      Upon save, this assessment will show up in the Assignments List, Gradebook, and Calendar in your LMS.
      <br/><br/>
      If you don't want this to happen, turn off "Enable Sync to LMS" before saving.
    </div>
  ),
  failedGradeSyncConfMessage: (
    <div>
      We couldn’t sync this assessment to your LMS.
      <br/><br/>
      Try again or reach out to your faculty success manager to troubleshoot.
    </div>
  ),
  failedGradeSyncOneOrMoreConfMessage: (
    <div>
      We couldn’t sync one or more assessments to your LMS.
      <br/><br/>
      Try again or reach out to your faculty success manager to troubleshoot.
    </div>
  ),
  openDatePastDueDateConfMessage: (
    <div>
      The due date of an assessment must be later than the open date.
      Please adjust before saving.
    </div>
  ),
  dueDatePastLateDateConfMessage: (
    <div>
      The late date of an assessment must be later than the due date.
      Please adjust before saving.
    </div>
  ),
  changeDueDateAfterStudentsStartedConfMessage: (
    <div>
      The current due date of this assessment has passed.
      Students who did not complete the assessment before that date may have answered questions in the Study Path for reduced points (if a late penalty was in place) or practice.
      These attempts will be re-graded once the due date is extended.
    </div>
  ),
  changeLateDateAfterStudentsStartedConfMessage: (
    <div>
      The current late date of this assessment has passed.
      Students who did not complete the assessment before that date may have answered questions in the Study Path for practice.
      These attempts will be re-graded once the assessment the late data is extended.
    </div>
  ),
};
export default confirmationMsgs;

export function hasBeenStartedMessage(isSummative: boolean) {
  const tooltipMessage = isSummative ? (
    <div className="has-been-started__tooltip">
      <div className="assessment-builder__message-tooltip-header">
        {sharedStrings.HAS_BEEN_STARTED_TOOLTIP_HEADER_STUDY_PATH}
      </div>
      <br/>
      <div>You can:</div>
      <ul>
        <li>change the due date to any date that has not already passed</li>
        <li>change the Study Path name</li>
        <li>add or remove assessments from the Assessments to Review list</li>
        <li>add Prep Questions</li>
        <li>modify the Practice Test, as long as no students have started taking it</li>
        <li>remove questions or change points assigned to questions that no students have answered</li>
      </ul>
      <br/>
      <div>Contact your {sharedStrings.INSTRUCTOR_SUPPORT_TITLE} if you would like to:</div>
      <ul>
        <li>change the grading policy or open date</li>
        <li>reorder questions</li>
        <li>remove questions or change points assigned to questions that students have answered</li>
        <li>unpublish or delete the Study Path</li>
      </ul>
    </div>
  ) : (
    <div className="has-been-started__tooltip">
      <div className="assessment-builder__message-tooltip-header">
        {sharedStrings.HAS_BEEN_STARTED_TOOLTIP_HEADER}
      </div>
      <br/>
      <div>You can:</div>
      <ul>
        <li>change the due date to any date that has not already passed</li>
        <li>change the assessment name</li>
        <li>change class days covered</li>
        <li>remove questions or change points assigned to questions that no students have answered</li>
      </ul>
      <br/>
      <div>Contact your {sharedStrings.INSTRUCTOR_SUPPORT_TITLE} if you would like to:</div>
      <ul>
        <li>change open date or grading policy</li>
        <li>remove questions or change points assigned to questions that students have answered</li>
        <li>reorder or add questions</li>
        <li>unpublish or delete the assessment</li>
      </ul>
    </div>
  );
  return tooltipMessage;
}

export function questionSelectorCardTooltipMessage(assessType: AssessTypeEnum) {
  if (assessType === AssessTypeEnum.Prep) {
    return (
      <div>
        <div className="question-list-sidebar__tooltip-header">
          {sharedStrings.HAS_BEEN_STARTED_TOOLTIP_HEADER_STUDY_PATH}
        </div>
        <div className="question-list-sidebar__tooltip-body">
          You can still:
          <ul>
            <li>add questions (this ability is limited to Prep Questions)</li>
            <li>change points assigned to or remove questions that no students have answered</li>
          </ul>
          <br/>
          You can no longer:
          <ul>
            <li>reorder questions</li>
            <li>change points assigned to or remove questions that students have answered</li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="question-list-sidebar__tooltip-header">
          {sharedStrings.HAS_BEEN_STARTED_TOOLTIP_HEADER}
        </div>
        <div className="question-list-sidebar__tooltip-body">
          You can still:
          <ul>
            <li>change points assigned to or remove questions that no students have answered</li>
          </ul>
          <br/>
          You can no longer:
          <ul>
            <li>add or reorder questions</li>
            <li>change points assigned to or remove questions that students have answered</li>
          </ul>
        </div>
      </div>
    );
  }
}


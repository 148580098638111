import { customAlphabet } from 'nanoid';
import { UserApi } from 'types/backend/users.types';
import { RoleEnum } from 'types/backend/roles.types';
// lighten or darken color
export const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);
  R = R * (100 + percent) / 100;
  G = G * (100 + percent) / 100;
  B = B * (100 + percent) / 100;
  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;
  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export const simpleConfirm = (message: string) => {
  return window.confirm(message);
};

export const randomNumber = (len = 8) => {
  const random = customAlphabet('1234567890', len);
  return parseInt(random());
};

//get a random negative integer to use for temp ids
export const randomTempId = () => {
  const random = randomNumber(8) * -1;
  return random;
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const truncateMiddle = (str: string) => {
  if (str.length > 30) {
    return `${str.substr(0, 20)}...${str.substr(str.length - 8, str.length)}`;
  }
  return str;
};

export const arrayToSentence = (arr: Array<string | undefined>) => {
  if (arr.length === 0) {
    return {
      text: '',
      plural: false,
    };
  }
  const uniqued = [...new Set(arr)];
  const last = uniqued.pop();
  const plural = uniqued.length > 0;
  if (!plural) {
    return { text: last, plural: false };
  }
  const text = `${uniqued.join(', ')} and ${last}`;
  return {
    text,
    plural,
  };
};

//TODO: decide if this should be removed or not
export const isInstructorRole = (user: UserApi) => {
  if (!user) {
    return false;
  }
  return user.roleId === RoleEnum.Instructor;
};

export const isAdminRole = (user: UserApi) => {
  if (!user) {
    return false;
  }
  return user.roleId === RoleEnum.Admin;
};

export const validUuid = (uuid: string) => {
  const uuidMatch = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidMatch.test(uuid);
};

export const validUrl = (url: string) => /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@$*:%_+.,~#?&/=[\]…']*)$/.test(url);

export const validEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const compareNumberArrays = (a: Array<number>, b: Array<number>) => {
  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    if (a[i] > b[i]) {
      return 1;
    }
    if (a[i] < b[i]) {
      return -1;
    }
    if (!!a[i] && !b[i]) {
      return 1;
    }
    if (!a[i] && !!b[i]) {
      return -1;
    }
  }
  return 0;
};

export function delay(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function splitArrayIntoChunks<T>(array: Array<T>, numberOfChunks: number) {
  const result = [];
  const fullArray = [...array];
  for (let i = numberOfChunks; i > 0; i--) {
    result.push(fullArray.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

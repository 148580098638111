import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BiCog } from 'react-icons/bi';
import { FaBars } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { StudentCoursePath } from 'types/student.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { StudentRoles, InstructorRoles } from 'types/backend/roles.types';
import './CourseHeaderNav.scss';

interface CourseHeaderNavLinkItem {
  disabled?: boolean
  id: string
  coursePath: StudentCoursePath | InstructorCoursePath | string
  exact?: boolean
  label: string
  noPrefix?: boolean
  renderLabel?: (label: string) => React.ReactNode
  specialNavItem?: boolean
  itemClassName?: string
  icon?: React.ReactNode
  show?: boolean
}
interface CourseHeaderNavProps {
  title?: string
  linkPrefix?: string
  leftTabs: Array<CourseHeaderNavLinkItem>
  rightTabs?: Array<CourseHeaderNavLinkItem>
  userRole?: InstructorRoles.Instructor | StudentRoles.Student
}

const CourseHeaderNav = ({
  linkPrefix = '',
  leftTabs,
  rightTabs = [],
  title,
  userRole = StudentRoles.Student,
}: CourseHeaderNavProps) => {
  const menuPopupRef = useRef(null);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const { pathname } = useLocation();
  // we need to know if we're in the course planner to correctly highlight the container when we are in either course planner
  const withinCoursePlanner = [InstructorCoursePath.CoursePlanner, InstructorCoursePath.BetterCoursePlanner].find((route) => {
    // this leading slash is important, so we don't return a false positive for dailyplanner
    return pathname.includes(`/${route}`);
  });
  useOnClickOutside(menuPopupRef, () => {
    if (menuExpanded) {
      setMenuExpanded(false);
    }
  });
  const renderTabGroup = (tabs: Array<CourseHeaderNavLinkItem>) => {
    return tabs.reduce((acc: Array<JSX.Element>, tab) => {
      const {
        coursePath,
        disabled = false,
        show = true,
        id,
        label,
        icon,
        noPrefix = false,
        renderLabel,
        specialNavItem,
        ...props
      } = tab;
      if (show) {
        if (specialNavItem) {
          acc.push(
            <div
              data-disabled={disabled}
              data-coursepath={coursePath}
              className={`course-header-nav__tab-bar__item special-item ${withinCoursePlanner ? 'active' : ''}`}
              key={coursePath}
              {...props}
            >
              <NavLink
                className="special-item__label"
                onClick={() => setMenuExpanded(false)}
                to={`${linkPrefix}${InstructorCoursePath.BetterCoursePlanner}`}
              >
                Course Planner
              </NavLink>
              <NavLink
                className="special-item__sub-item sub-item__hero"
                onClick={() => setMenuExpanded(false)}
                to={`${linkPrefix}${InstructorCoursePath.BetterCoursePlanner}`}
              >
                Enhanced <small>beta</small>
              </NavLink>
              <NavLink
                className="special-item__sub-item"
                onClick={() => setMenuExpanded(false)}
                to={`${linkPrefix}${InstructorCoursePath.CoursePlanner}`}
              >
                Simple
              </NavLink>

            </div>
          );
        } else {
          acc.push(
            <NavLink
              data-disabled={disabled}
              data-coursepath={coursePath}
              className="course-header-nav__tab-bar__item"
              onClick={() => setMenuExpanded(false)}
              to={`${noPrefix ? '' : linkPrefix}${coursePath}`}
              key={coursePath}
              {...props}
            >
              {!!renderLabel
                ? renderLabel(label)
                : <>{!!icon && icon}{label}</>
              }
            </NavLink>
          );
        }
      }
      return acc;
    }, []);
  };
  return (
    <div className="course-header-nav">
      <div className="course-header-nav__title-bar">
        <div className="course-header-nav__title">
          <h1 className="h2 title-bar__text">{title || 'Course loading...'}</h1>
          {userRole === InstructorRoles.Instructor && (
            <NavLink className="course-header-nav__edit-button" to={`${linkPrefix}${InstructorCoursePath.Edit}`}>
              <BiCog/>
            </NavLink>
          )}
        </div>
        <div className="course-header-nav__mobile-menu-wrap hide-sm" data-expanded={menuExpanded}>
          <button aria-expanded={menuExpanded} className="course-header-nav__mobile-menu-button" title="Expand Course Menu" onClick={() => setMenuExpanded(!menuExpanded)}>
            <FaBars />
          </button>
          {menuExpanded && (
            <nav aria-label="Course Navigation" className="course-header-nav__mobile-menu" ref={menuPopupRef}>
              {renderTabGroup([...leftTabs, ...rightTabs])}
            </nav>
          )}
        </div>
      </div>
      <nav aria-label="Course Navigation" className="course-header-nav__tab-bar-holder hide-xs-only">
        <div className="course-header-nav__tab-bar tab-bar__left">
          {renderTabGroup(leftTabs)}
        </div>
        <div className="course-header-nav__tab-bar tab-bar__right">
          {renderTabGroup(rightTabs)}
        </div>
      </nav>
    </div>
  );
};

CourseHeaderNav.propTypes = {
  leftTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  rightTabs: PropTypes.arrayOf(PropTypes.object),
  userRole: PropTypes.string,
  title: PropTypes.string,
  linkPrefix: PropTypes.string,
};

export default CourseHeaderNav;

/**
 * retrieveEnrichedQuestionGroups
 *
 * Get all question groups by questionGroupId, sort questions according to order and return Array<EnrichedQuestionGroup>
*/
import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'utils/groupBy';
import retrieveActiveCombinedQuestions, { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { QuestionGroupQuestionWithGroupApi } from 'types/backend/questionGroupQuestions.types';
import { Store } from 'types/store.types';
import { YesNo } from 'types/backend/shared.types';

export interface EnrichedQuestionGroup extends Pick<QuestionGroupQuestionWithGroupApi, 'questionGroupId' | 'groupTitle'> {
  groupQuestions: Array<ActiveCombinedQuestion>
  groupQuestionIds: Array<number>
}

export default createSelector(
  (store: Store) => store.active.questionGroups,
  retrieveActiveCombinedQuestions,
  (questionGroups, activeCombinedQuestions) => {
    const groupedByGroupId = groupBy(questionGroups, ({ questionGroupId }) => questionGroupId.toString());
    const enrichedSortedQuestionGroups: Array<EnrichedQuestionGroup> = Object.values(groupedByGroupId).map((arrayOfQuestionGroups) => {
      const [{ questionGroupId, groupTitle }] = arrayOfQuestionGroups;
      const sortedQuestionGroups = arrayOfQuestionGroups.sort((a, b) => a.order - b.order);
      const groupQuestionIds = sortedQuestionGroups.map(({ questionId }) => questionId);
      const groupQuestions = sortedQuestionGroups.reduce((acc: Array<ActiveCombinedQuestion>, cur) => {
        const acq = activeCombinedQuestions.find(({ id }) => id === cur.questionId);
        if (!!acq && acq?.active === YesNo.Yes) {
          acc.push(acq);
        }
        return acc;
      }, []);
      return {
        questionGroupId,
        groupTitle,
        groupQuestions,
        groupQuestionIds, // convenience array for easier lookup
      };
    });
    return enrichedSortedQuestionGroups;
  }
);

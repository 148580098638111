import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';

import sharedStrings from 'sharedStrings';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { UneditableSelectedLearningObjective } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';
import { AddRemoveEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import './QuestionBuilderLoChooser.scss';

const QuestionBuilderLoTable = ({
  disabled = false,
  los,
  mode,
  onChangeLoSelectedState,
  uneditableSelectedTemplateLos,
  uneditableSelectedUserLos,
}: {
  disabled?: boolean
  los: Array<EnrichedCourseLearningObjective>
  mode?: AddRemoveEnum
  onChangeLoSelectedState: (loId: number) => void
  uneditableSelectedTemplateLos: Array<UneditableSelectedLearningObjective>
  uneditableSelectedUserLos: Array<UneditableSelectedLearningObjective>
}) => {
  const topics = useSelector((store: Store) => store.active.topics);
  const getTopicName = (topicId: number) => {
    const { name } = topics.find((topic) => topic.id === topicId) || {};
    if (!name) {
      console.error(`topicId ${topicId} not found in topics`, topics);
      return '';
    }
    return name;
  };
  const renderUneditableSelectedLo = (qloType: LibraryTypeEnum, { topicId, stringId, title, type, _derived: { loNumber } }: UneditableSelectedLearningObjective) => {
    return (
      <tr key={`question-lo-picker__row${qloType}${stringId}`}>
        <td className="question-lo-picker__action">
          <BetterTooltip
            content={
              <div>
                {qloType === LibraryTypeEnum.Template && type === LibraryTypeEnum.Template ? (
                  <p>Codon-authored LOs cannot be removed from Codon-authored items.</p>
                ) : (
                  <p>Your co-instructor linked this item with this LO. To unlink them, reach out to your co-instructor or {sharedStrings.INSTRUCTOR_SUPPORT_TITLE}.</p>
                )}
              </div>
            }
            inModal
          >
            <button
              className="question-lo-picker__remove-button"
              data-stringid={stringId}
              disabled
            >
              <FaMinusCircle />
            </button>
          </BetterTooltip>
        </td>
        <td className="question-lo-picker__lonumber">
          {loNumber}
        </td>
        <td>
          <b>{getTopicName(topicId)}</b>: {title}
        </td>
      </tr>
    );
  };

  return (
    <table className="question-lo-picker" data-mode={mode}>
      <tbody>
        {uneditableSelectedTemplateLos.map(lo => renderUneditableSelectedLo(LibraryTypeEnum.Template, lo))}
        {uneditableSelectedUserLos.map(lo => renderUneditableSelectedLo(LibraryTypeEnum.User, lo))}
        {los.map(({ id, topicId, stringId, title, type, _derived: { loNumber } }) => (
          <tr key={`question-lo-picker__row${stringId}`} data-lotype={type} data-lo-stringid={stringId}>
            <td className="question-lo-picker__action">
              <button
                className="question-lo-picker__action-button"
                data-stringid={stringId}
                disabled={disabled}
                onClick={() => onChangeLoSelectedState(id)}
              >
                {mode === AddRemoveEnum.Remove ? <FaMinusCircle /> : <FaPlusCircle />}
              </button>
            </td>
            <td className="question-lo-picker__lonumber">
              {loNumber}
            </td>
            <td>
              <b>{getTopicName(topicId)}</b>: {title}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

QuestionBuilderLoTable.propTypes = {
  los: PropTypes.array.isRequired,
  uneditableSelectedTemplateLos: PropTypes.array.isRequired,
  uneditableSelectedUserLos: PropTypes.array.isRequired,
};

export default QuestionBuilderLoTable;
